import React, { useState, useEffect } from 'react';
import storiesData from './stories.json'; // Replace with the correct path

function StoryList({ onSelectStory }) {
    return (
      <div className="story-list">
        {storiesData.map(story => (
          <div key={story.id} className="story-card">
            <h3>{story.title}</h3>
            <p>by {story.author}</p>
            <button className="door-button" onClick={() => onSelectStory(story)}>Enter Story</button>
          </div>
        ))}
      </div>
    );
  }
  

function StoryViewer({ story, onBack, onBackgroundChange }) {
  const [currentChapterId, setCurrentChapterId] = useState(story.chapters[0].id);

  const currentChapter = story.chapters.find(chapter => chapter.id === currentChapterId);

  useEffect(() => {
    onBackgroundChange(currentChapter.backgroundImage);
  }, [currentChapterId, currentChapter, onBackgroundChange]);

  const handleDecision = (nextChapterId) => {
    setCurrentChapterId(nextChapterId);
  };

  return (
    <div className='story-item'>
      <button className='back-to-story-button' onClick={onBack}>Back to Stories</button>
      <h2>{story.title}</h2>
      <h4>{currentChapter.title}</h4>
      <p>{currentChapter.content}</p>
      {currentChapter.decisions.map(decision => (
        <button class='decision-button' key={decision.nextChapterId} onClick={() => handleDecision(decision.nextChapterId)}>
          {decision.text}
        </button>
      ))}
    </div>
  );
}

function Story({ onBackgroundChange, defaultBackgroundImage }) {
    const [selectedStory, setSelectedStory] = useState(null);

  const handleSelectStory = (story) => {
    setSelectedStory(story);
  };

  const handleBack = () => {
    onBackgroundChange(defaultBackgroundImage);

    setSelectedStory(null);
  };

  return (
    <div>
      {!selectedStory ? (
        <StoryList onSelectStory={handleSelectStory} />
      ) : (
        <StoryViewer story={selectedStory} onBack={handleBack} onBackgroundChange={onBackgroundChange}/>
      )}
    </div>
  );
}

export default Story;
