import React, { useState } from 'react';
import './App.css';
import Story from './components/Story'; // Ensure the path is correct
import defaultBackgroundImage from './images/cyoa-cover.png'; // Import the default background image


function App() {
  const [backgroundImage, setBackgroundImage] = useState(defaultBackgroundImage);

  const appStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  };

  return (
    <div className="App" style={appStyle}>
      <header className="App-header">
        <h1>Welcome to the Land of Adventures</h1>
      </header>
      <main>
        <Story onBackgroundChange={setBackgroundImage} defaultBackgroundImage={defaultBackgroundImage} />
      </main>
    </div>
  );
}

export default App;

